/* http://meyerweb.com/eric/tools/css/reset/ /* http://meyerweb.com/eric/tools/css/reset/ /* http://meyerweb.com/eric/tools/css/reset/ /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
// less-watch-compiler less css index.less

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

html {
  font-size: 62.5%;
}
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  //font-family: "Elsie", cursive;
}

body {
  background: #212a43;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.wrapper.creditContainer {
  justify-content: center;
  align-items: center;
  flex-basis: 50%;

  .creditContainer__stats{
    width: 80%;
    margin-bottom: 2rem;
    background: #151a30;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0 0px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;

    h1{
      font-size: 24px;
      text-align: center;
      padding: 0 0 1rem;
      border-bottom: 1px solid white;
      width: 100%;
    }
    tbody{
      border-top: 1px solid white;
    }
    th, td{
      padding: 1.6rem 0.7rem;
      box-sizing: border-box;
      border-right: 1px solid white;
      text-align: center;
    }
    th:last-child, td:last-child{
      border-right: none;
    }
  }
  .registerForm {
    width: 80% !important;
  }
}

.wrapper.detailsContainer {
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 50%;
  height: 100vh;
  padding: 50px 0;

  input.creditInput {
    flex-basis: 30% !important;
  }

  input.searchInput {
    width: 200%;
  }

  input {
    font-family: Mulish;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    height: 40px;
    border: 1px solid #414c6c;
    border-radius: 15px;
    padding-left: 20px;
    z-index: 1;
  }

  input:focus {
    outline: none;
  }

  .detailItemContainer {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    width: 90%;
    background: #151a30;
    border-radius: 18px;
    padding: 20px;

    #message-id {
      font-size: 20px;
    }

    h2 {
      font-size: 23px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 19px;
    }

    p {
      font-size: 16px;
    }

    p,
    h2,
    h3 {
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.20000000298023224px;
    }

    button {
      font-family: Mulish;
      padding: 10px 15px;
      background: #0676ed;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      border-radius: 15px;
      transform: matrix(1, 0, 0, 1, 0, 0);
      filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
      // margin: 0 0 10px 0;
      outline: none;
      border: none;
    }

    .inputContainer {
      display: flex;
      align-items: center;
    }

    .innerContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .info {
        margin-top: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.wrapper.adminContainer {
  justify-content: space-around;
  flex-direction: row !important;

  .unauthorizedMessage {
    font-size: 30px;
  }
}

.wrapper.registerContainer {
  justify-content: space-between;
}

.wrapper.promptContainer {
  justify-content: baseline;
  padding: 10px;
  .logo {
    width: 150px;
    margin-bottom: 30px;
    margin-top: 30px;

    @media only screen and (min-width: 500px) {
      margin-top: 0;
      width: 300px;
    }
  }

  @media only screen and (min-width: 500px) {
    justify-content: center;
    padding: 0;
  }

  .data-desc {
    width: 100%;
    margin-bottom: 30px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 500px) {
      width: 80%;
    }

    h2 {
      text-align: center;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.20000000298023224px;
      margin-bottom: 20px;

      @media only screen and (min-width: 400px) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    .dataContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      list-style: none;

      li.center {
        text-align: center;
      }

      li {
        &:before {
          content: "- ";
          text-indent: -5px;
        }

        p {
          margin-left: 20px;
          margin-top: -10px;
          margin-bottom: -10px;
        }

        a {
          text-decoration: underline;
        }

        text-indent: -5px;
        margin-bottom: 20px;
        text-align: left;
        font-family: Mulish;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;

        @media only screen and (min-width: 400px) {
          font-size: 17px;
        }
      }
    }
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    @media only screen and (min-width: 500px) {
      width: auto;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
      margin-top: -32px;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      button,
      a {
        border: none;
        padding: 20px 20px;
        flex-shrink: 1;
        background: #0676ed;
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border-radius: 20px;
        transform: matrix(1, 0, 0, 1, 0, 0);
        filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
        margin: 0 15px;
        outline: none;
        border: none;
      }
    }

    .remindMe {
      margin-top: 20px;

      label {
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 0;
  z-index: 100;
  width: 80%;
  height: 100vh;
  background: #212a43;
  border-left: 1px solid white;

  h4 {
    display: flex;
    color: white;
    align-items: center;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.10000000149011612px;
    margin: 10px 15px;

    .moreCreditsBtn, .logout {
      cursor: pointer;
    }

    .divider {
      margin: 10px;
      font-weight: 900;
    }

    .creditLeft.no-credit {
      color: red;
      font-weight: 1000;
    }
  }

  button {
    cursor: pointer;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
    border: none;
    outline: none;
    font-size: 30px;
    color: white;
    background-color: transparent;
  }

  a {
    display: flex;
    padding: 10px 10px;
    flex-shrink: 1;
    background: #0676ed;
    font-family: Mulish;
    font-style: normal;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    border-radius: 20px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
    margin: 10px 15px;
    outline: none;
    border: none;
  }

  @media only screen and (min-width: 500px) {
    width: 50%;
  }
}

.wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  background: transparent;
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // margin: 0 auto;
  // overflow: scroll;

  button {
    cursor: pointer;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .innerContainer {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      display: flex;

      @media only screen and (min-width: 700px) {
        display: none;
      }
    }

    .navButton {
      display: none;
      padding: 10px 10px;
      flex-shrink: 1;
      background: #0676ed;
      font-family: Mulish;
      font-style: normal;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      border-radius: 20px;
      transform: matrix(1, 0, 0, 1, 0, 0);
      filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
      margin: 0 15px;
      outline: none;
      border: none;
      @media only screen and (min-width: 700px) {
        display: flex;
      }
    }

    img {
      width: 200px;
    }

    img[alt="menu"] {
      width: 30px !important;
      height: 30px;
    }

    button {
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }

    h4 {
      display: none;
      align-items: center;
      font-family: Mulish;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.10000000149011612px;

      .moreCreditsBtn, .logout {
        cursor: pointer;
      }

      .divider {
        margin: 10px;
        font-weight: 900;
      }

      .creditLeft.no-credit {
        color: red;
        font-weight: 1000;
      }

      @media only screen and (min-width: 700px) {
        display: flex;
      }
    }
  }

  /* circular loader starts */

  /* #loader2,
#loaderSTOF2 {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  /* animation: spin 1s linear infinite;
} */

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* circular loader ends */

  #loader
  // #loaderGH
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;

    h2 {
      font-family: Mulish;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
    }
  }

  #lds-ripple
  // #lds-rippleGH
  // #lds-rippleSTOF
  {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    left: -25px;
    bottom: -5px;
  }

  #lds-ripple div
  // #lds-rippleGH div
  // #lds-rippleSTOF div
  {
    position: absolute;
    /* border: 4px solid #fff; */
    border: 4px solid darkturquoise;
    opacity: 1;
    margin-left: 20px;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  #lds-ripple div:nth-child(2)
  // #lds-rippleGH div:nth-child(2)
  // #lds-rippleSTOF div:nth-child(2)
  {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

  #loader.hide,
  #container.hide {
    display: none;
  }

  #loader.show,
  #container.show
  /* #loader2.show, */
  /* #github.show,
  #stackoverflow.show, */
  /* #loaderSTOF2.show  */
  // #loaderSTOF.show
  {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // width: 100%;
    // height: 400px;
    display: flex;
  }

  .container {
    justify-content: center;
    width: 100%;
    flex: 2;
  }

  #home.hide,
  #search.hide,
  #moreCredits.hide,
  #loginForm.hide,
  #registerForm.hide {
    display: none;
  }

  #home.show,
  #search.show,
  #moreCredits.show,
  #loginForm.show,
  #registerForm.show {
    display: flex;
    flex-direction: column;
  }

  #home.show {
    justify-content: center;
    align-items: center;
  }

  #getMoreCredits {
    @media only screen and (min-width: 770px) {
      width: 50%;
    }

    display: flex;
    background-color: #151a30;
    border-radius: 20px;
    border: 1px solid #414c6c;
    position: absolute;
    top: 50px;
    width: 80%;
    z-index: 100;

    .moreCreditContainer {
      width: 100%;
      height: 100%;
      position: relative;

      .closeContainer {
        padding: 20px 20px 0 0;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;

        button {
          &:focus {
            outline: none;
          }

          outline: none;
          border: none;
          background-color: transparent;
          font-size: 30px;
          color: white;
        }
      }

      .getMoreCredits {
        h2,
        p,
        select,
        option {
          font-family: Mulish;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          // line-height: 55px;
          letter-spacing: 0em;
          text-align: center;
        }

        select,
        option {
          &:focus {
            outline: none;
          }

          color: white;
          background-color: #37466e;
          border: none;
          border-radius: 20px;
          padding: 10px 15px;
          font-size: 15px !important;
          font-weight: 500 !important;
          margin-bottom: 30px;
        }

        p {
          font-size: 15px !important;
          margin-top: 10px;
        }

        display: flex;
        position: relative;
        z-index: 1;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 50px;

        hr {
          margin-top: 20px;
          width: 100%;
        }

        form {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          height: 70%;
        }
      }
    }
  }

  main {
    .userFormContainer {
      display: flex;
      justify-content: center;
      width: 80%;
      padding: 20px 0;

      div {
        width: 75%;
      }

      p {
        text-align: center;
        padding: 20px 0;
      }

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        select {
          @media only screen and (min-width: 500px) {
            width: 20%;
            margin-bottom: 0;
          }
          width: 80%;
          margin-bottom: 10px;
          display: inline-block;
          box-sizing: border-box;
          -webkit-appearance: none;
          -moz-appearance: none;
          margin-right: 30px;
          background-image: linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%);
          background-position: calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
          background-size: 5px 5px, 5px 5px, 1px 1.5em;
          background-repeat: no-repeat;
        }

        .inputContainer {
          display: flex;
          align-items: center;
          width: 100%;

          @media only screen and (min-width: 770px) {
            width: 70% !important;
          }
        }

        button {
          display: flex;
          align-items: center;
          &:focus {
            outline: none;
          }
          border: none;
          background-color: #151a30;
          border-radius: 20px;
          margin-left: -55px;
          height: 33px;
          padding: 15px;
          z-index: 5;
        }

        img {
          width: 20px;
          height: 20px;
        }

        input {
          width: 100%;
          height: 40px;

          &:focus {
            background-color: white;
            color: black;
          }
        }

        select,
        input,
        option {
          background-color: #151a30;
          color: white;
          font-family: Mulish;
          font-style: normal;

          &:focus {
            outline: none;
          }
          border: none;
          border-radius: 20px;
          padding: 10px 15px;
        }

      }
    }

    .profile {
      display: flex;
      width: 80%;
      height: 100px;
      box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.298214);
      border-radius: 20px;

      .nameLocation {
        display: flex;
        align-items: center;
        background: #151a30;
        flex: 3;
        border-radius: 10px 0 0 10px;

        img {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          margin-left: 2.5rem;
        }

        div {
          flex-direction: column;
          font-family: Mulish;
          font-style: normal;
          margin-left: 1rem;

          h3 {
            font-size: 16px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0.10000000149011612px;
          }

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 10px;
            letter-spacing: 0.30000001192092896px;
            align-self: flex-start;
            color: #8f9bb3;
          }
        }
      }

      .followers {
        background: #1a1f38;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: Mulish;
        flex: 2;
        margin-left: 0.25rem;
        border-radius: 0 10px 10px 0;

        p {
          font-style: normal;
          font-size: 8px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0.3499999940395355px;
          margin-left: 4.5rem;
          color: #57627b;
        }

        h4 {
          font-size: 14px;
          font-style: italic;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.30000001192092896px;
          margin-left: 4.5rem;
        }
      }
    }

    .search-message-container {

      padding: 40px;
      p {
        font-size: 16px;
        text-align: center;
      }

      h1 {
        font-size: 30px;
        font-family: Mulish;
        font-style: normal;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-align: center;
      }
    }

    .other-ranking,
    .gitHub-ranking,
    .stackoverflow-ranking {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin-top: 80px;
      box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.298214);
      border-radius: 20px;
      background: #151a30;

      .other-ranking-heading,
      .gitHub-ranking-heading,
      .stackoverflow-ranking-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0px 10px 0px;
        padding: 0 20px;
        flex-direction: column;

        @media (max-width: 1220px) {
          flex-direction: column !important;
        }

        .btn-login{
          flex: 2 1 auto !important;
          display: flex;
          justify-content: center;

          div{
            width: fit-content;

            p{
              font-size: 12px !important;
            }
          }
        }

        @media only screen and (min-width: 600px) {
          flex-direction: row;
          .username-title {
            order: 1;
          }
          img {
            order: 2;
          }
        }

        .titleToolTip {
          position: relative;

          a{
            margin-bottom: 1.5rem !important;
          }

          .tooltiptext {
            display: flex;
            visibility: hidden;
            width: 120px;
            background-color: #2e3a59;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 10;
            top: -30px;
            left: 5px;
            font-size: 12px;
            line-height: 14px;
            font-family: Mulish;
          }
        }

        .titleToolTip:hover .tooltiptext {
          visibility: visible;
        }

        img[alt="new-tab"] {
          flex: 0 0 20px !important;
          height: 20px !important;
          margin-left: 10px;
          flex-grow: 0 !important;
          order: unset !important;
        }

        .username-title {
          flex: 2 1 auto;
          //flex-basis: 30%;
          display: flex;
          order: 2;

          @media only screen and (min-width: 768px) {
            //flex-grow: 1;
          }

          @media only screen and (min-width: 600px) {
            order: 0;
          }
        }

        h3 {
          flex: 0 1 auto;
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.10000000149011612px;
          text-align: center;

          @media only screen and (min-width: 600px) {
            text-align: left;
          }
          @media (max-width: 600px) {
            line-height: 20px;
            margin-top: 16px;
          }
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          &:hover {
            color: #0676ed;
          }
          text-decoration: underline;

          p {
            word-break: break-word;
            white-space: pre-line;
            overflow-wrap: break-word;
            -ms-word-break: break-word;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
            margin-right: 5px;
            
          }

          @media only screen and (min-width: 600px) {
            //justify-content: flex-start;
          }
        }

        img {
          order: 1;
          flex: 0 0 50px;
          height: 50px;

          @media only screen and (min-width: 600px) {
            order: 0 !important;
          }
        }
      }

      .other-ranking-details {
        span {
          flex-direction: column;
        }
      }

      .other-ranking-details,
      .gitHub-ranking-details,
      .stackoverflow-ranking-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        // padding: 0 20px;

        .p1{
          font-size: 13px;
          text-align: center;
        }

        .p1_top_part{
          // border-top: 2px solid #2e3a59;
          width: 100%;
          padding-top: 1rem;
        }

        span{
          padding: 0 20px;
        }
        .tooltiptext_ranking{
          text-align: center !important;
          font-family: Mulish !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 22px !important;
          letter-spacing: 0.30000001192092896px !important;
          margin-bottom: 6px !important;
        }

        .gitHub-ranking-details-pa{
          text-align: center !important;
          font-family: Mulish !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 22px !important;
          letter-spacing: 0.30000001192092896px !important;
          margin-bottom: 6px !important;
        }
        
        .btn-authorize {
          img {
            color: white;
          }

          padding: 20px 20px;
          flex-basis: 200%;
          flex-shrink: 1;
          background: #0676ed;
          font-family: Mulish;
          font-style: normal;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          border-radius: 20px;
          transform: matrix(1, 0, 0, 1, 0, 0);
          filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
          margin: 0 15px;
          outline: none;
          border: none;
        }

        .p1-job-hunting {
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
          border-top: 2px solid #2e3a59;
          width: 100%;
          padding: 0 20px;
          
            span{
              display: block;
              margin: 20px auto 0;
              font-family: Mulish !important;
              font-style: normal !important;
              font-weight: 600 !important;
              font-size: 18px !important;
              line-height: 20px !important;
              text-align: center !important;
              color: #ffffff !important;
            }
        }

        .p-other-desc {
          font-family: Mulish;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
          padding: 0 30px;
          width: 100%;
        }

        span {
          display: flex;
          align-items: center;
          margin: 5px 0;

          h2 {
            font-family: Mulish;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            // line-height: 55px;
            letter-spacing: 0em;
            text-align: center;
          }

          img {
            width: 20px;
            margin-left: 10px;
          }

          .tooltip {
            position: relative;
            padding: 0px;
            
            .tooltiptext {
              visibility: hidden;
              width: 120px;
              background-color: #2e3a59;
              color: #fff;
              text-align: center;
              border-radius: 6px;
              padding: 5px 0;
              position: absolute;
              z-index: 1;
              top: -5px;
              right: 110%;
              font-size: 12px;
              line-height: 14px;
              font-family: Mulish;
            }

            .tooltiptext::after {
              content: "";
              position: absolute;
              top: 15%;
              right: 100%;
              margin-top: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent #2e3a59 transparent transparent;
            }
          }

          .tooltip:hover .tooltiptext {
            visibility: visible;
          }
        }

        .p2 {
          font-family: Mulish;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
        }

        .p3 {
          font-family: Mulish;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
          margin-top: 5px;
        }
      }

      .soft-skills {
        flex-direction: column;
        align-items: center;

        p {
          font-size: 13px !important;
          margin-bottom: 5px !important;
        }
        h2,
        p {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 20px;
        }

        .iconContainer {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;

          @media only screen and (min-width: 583px) {
            flex-direction: row;
          }

          div {
            padding: 0 20px;
            img {
              margin-bottom: 10px;
              width: 20px;
              height: 20px;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: auto;

            @media only screen and (min-width: 583px) {
              flex-basis: 30%;
            }
          }

          div:first-child, div:nth-child(2) {
            margin-bottom: 30px;

            @media only screen and (min-width: 583px) {
              margin-bottom: 0;
            }
          }

          div:last-child {
            margin-bottom: 0 !important;
          }
        }
      }

      .other-ranking-register {
        .btn-authorize {
          width: auto !important;
          display: flex;
          align-items: center;
          padding: 15px 15px;
          margin-bottom: 10px !important;

          @media only screen and (min-width: 483px) {
            margin-bottom: 0 !important;
          }
        }
      }

      .gitHub-ranking-share,  
      .stackoverflow-ranking-share {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 2px solid #2e3a59;
        padding: 20px;

        @media (max-width: 1220px) {
          flex-direction: column !important;
        }

        h1 {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 20px;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .other-ranking-register,
      .soft-skills{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-top: 2px solid #2e3a59;
        padding: 20px 0;

        h1 {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 20px;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }

        .btn-twitter,
        .btn-linkedIn,
        .btn-authorize {
          width: 115px;
          height: 35px;
          background: #0676ed;
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          border-radius: 20px;
          transform: matrix(1, 0, 0, 1, 0, 0);
          filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
          margin: 0 15px;
          outline: none;
          border: none;
        }

        .btn-linkedIn {
          background: #192038;
        }

        #btn-share-gitHub.hide,
        #btn-authorize-gitHub.hide,
        #btn-share-stackoverflow.hide,
        #btn-authorize-stackoverflow.hide {
          display: none;
        }

        #btn-share-gitHub.show,
        #btn-authorize-gitHub.show,
        #btn-share-stackoverflow.show,
        #btn-authorize-stackoverflow.show {
          display: flex;
        }

        @media (max-width: 600px) {
          padding: 15px;
        }
      }
    }
  }

  aside {
    display: flex;
    flex-direction: column;
    align-items: center;

    .searchGitHub {
      background: rgba(60, 100, 177, 0.06);
      margin-top: -25px;
    }

    .searchStackoverFlow {
      margin-bottom: -25px;
    }

    .searchGitHub,
    .searchStackoverFlow {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0;

      h2 {
        //styleName: H2 - bold 44 (56, 0.2px);
        font-family: Mulish;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
      }

      p {
        //styleName: Body 1 - regular 16 (22, 0.3px);
        font-family: Mulish;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.30000001192092896px;
        text-align: center;
        padding: 0 120px;
      }

      span {
        display: flex;

        input {
          width: 220px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 15px;
          margin: 18px 10px 10px 0;
        }

        input::placeholder {
          padding-left: 10px;
        }

        .btn-search {
          width: 100px;
          height: 30px;
          background: #0676ed;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          border-radius: 15px;
          transform: matrix(1, 0, 0, 1, 0, 0);
          filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
          margin: 18px 0 10px 15px;
          outline: none;
          border: none;
        }
      }
    }
  }

  #moreCredits {
    display: flex;
    flex-direction: column;
    align-items: center;

    .voucher {
      display: flex;
      flex-direction: column;
      width: 650px;
      align-items: center;
      margin-top: -15px;
      padding: 40px 0;
      background: rgba(60, 100, 177, 0.06);

      h2 {
        //styleName: H2 - bold 44 (56, 0.2px);
        font-family: Mulish;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
      }

      p {
        //styleName: Body 1 - regular 16 (22, 0.3px);
        font-family: Mulish;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.30000001192092896px;
        text-align: center;
        padding: 0 120px;
      }

      span {
        display: flex;

        input {
          width: 220px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 15px;
          margin: 18px 10px 10px 0;
        }

        input::placeholder {
          padding-left: 10px;
        }

        .btn-voucher {
          width: 100px;
          height: 30px;
          background: #0676ed;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          border-radius: 15px;
          transform: matrix(1, 0, 0, 1, 0, 0);
          filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
          margin: 18px 0 10px 15px;
          outline: none;
          border: none;
        }
      }
    }
  }

  #loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    width: 250px;
    height: 350px;
    background: #414c6c;
    box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.3);
    border-radius: 20px;

    .loginForm {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      background: #151a30;
      border-radius: 20px 20px 0 0;
      padding: 20px 0 64px 0;
      img {
        width: 35px;
      }
      h3 {
        font-family: Mulish;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.10000000149011612px;
        text-align: center;
      }
      span {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
          width: 160px;
          height: 25px;
          background: none;
          border: 1px solid #414c6c;
          border-radius: 15px;
          margin: 18px 0 10px 0;
        }

        input::placeholder {
          padding-left: 10px;
          background: none;
        }

        input:focus {
          background-color: #e8f0fe;
        }

        button {
          font-family: Mulish;
          width: 160px;
          height: 25px;
          background: #0676ed;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ffffff;
          border-radius: 15px;
          transform: matrix(1, 0, 0, 1, 0, 0);
          filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
          // margin: 0 0 10px 0;
          outline: none;
          border: none;
        }

        a p {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;

          color: #ffffff;
        }
      }
    }
    .signinWithGitHubOrRegister {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: #151a30;
      border-radius: 0 0 20px 20px;
      padding: 20px;
      margin-top: 1px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Mulish;
        width: 160px;
        height: 25px;
        background: #0676ed;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border-radius: 15px;
        transform: matrix(1, 0, 0, 1, 0, 0);
        filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
        margin: 0 0 10px 0;
        outline: none;
        border: none;

        img {
          width: 20px;
          margin-right: 10px;
        }
      }

      span {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        h4 {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 22px;
          /* or 137% */
          letter-spacing: 0.1px;
          color: #faf0f0;
        }
        a h4 {
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 22px;
          /* or 137% */
          letter-spacing: 0.1px;
          color: #4ac2ab;
          margin-left: 10px;
        }
      }
    }
  }

  .registerForm {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }

    @media only screen and (min-height: 700px) {
      height: 70%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    width: 80%;
    height: 95%;
    background: #414c6c;
    box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    .createAccount {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: #151a30;
      border-radius: 20px;
      // padding: 20px 0 64px 0;
      padding: 20px 0 37px;

      p {
        text-align: center;
        padding: 0 10px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        color: #faf0f0;
        margin-bottom: 20px;
      }

      .inputContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0 20px 0;

        @media only screen and (min-width: 576px) {
          width: 50%;
        }

        .error {
          border: 2px solid red !important;
        }

        .showError {
          display: flex !important;
        }

        .errorContainer {
          width: 98%;
          background-color: red;
          margin-top: -10px;
          z-index: 0;
          display: none;
          justify-content: center;
          align-items: flex-end;
          margin-bottom: 0;
          font-size: 15px;
          font-weight: bold;
          line-height: 20px;
          padding: 10px 10px;
        }

        input {
          font-family: Mulish;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          width: 100%;
          height: 40px;
          border: 1px solid #414c6c;
          border-radius: 15px;
          padding-left: 20px;
          z-index: 1;
        }

        input:focus {
          outline: none;
        }
      }

      button {
        font-family: Mulish;
        padding: 15px;
        width: 200px;
        background: #0676ed;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border-radius: 15px;
        transform: matrix(1, 0, 0, 1, 0, 0);
        filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
        // margin: 0 0 10px 0;
        outline: none;
        border: none;
      }
      .consent {
        display: flex;
        align-items: center;
        justify-content: center;
        /* width: 93%; */
        text-align: center;
        width: 65%;
        // margin: 10px;
        margin: 20px 0 30px 0;

        a,
        h4 {
          display: contents;
          font-family: Mulish;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          color: #ffffff;
        }
        a h4 {
          color: blueviolet;
        }
      }
    }

    .haveAnAccount {
      // display: flex;
      // align-items: center;
      // justify-content: space-evenly;
      // padding: 15px 0 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #151a30;
      padding: 15px 0 15px 0;
      margin-top: 1px;
      width: 100%;
      border-radius: 0 0 18px 18px;
      h4 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 22px;
        /* or 137% */
        letter-spacing: 0.1px;
        color: #faf0f0;
      }
      a h4 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 22px;
        /* or 137% */
        letter-spacing: 0.1px;
        color: #4ac2ab;
        margin-left: 5px;
      }
    }
  }
  .scroller{
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    width: 80%;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.10000000149011612px;
    display: block;
    margin: 3rem auto  0;
  
    h3{
      text-align: center !important;
      margin-left: 20px;
    }
  }
  .what-level{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(211, 211, 211, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-family: Mulish;
  
    .what-level_content{
      min-width: 80%;
      min-height: 30%;
      background: #151a30;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 20px;

      @media (max-width: 600px) {
        min-width: 90%;
        min-height: 75%;
      }

      .iconContainer{
        display: flex;
        justify-content: space-around;
        flex: 1;
        align-items: center;

        @media (max-width: 600px) {
          flex-direction: column;
        }
      }

      .what-level-header-wraper{
        width: 100%;
        padding: 1rem 2rem;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: space-between;

        h3{
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: 0.10000000149011612px;
        }

        img{
          width: 25px;
          height: 31px;
          cursor: pointer;
        }
      }

      .iconContainer > div{
        color: white;
        text-align: center;
        cursor: pointer;
        background: #0676ed;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border-radius: 20px;
        padding: 0.3rem 2rem;
        width: 20%;

        @media (max-width: 600px) {
          width: 75%;
        }
      }
      .iconContainer > div > img{
          width: 40px;
          height: 40px;
      }
    }
  }
  .candidate__info{
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;
    // margin-bottom: 6px;
  }
  .congrats, .non-congrats{

    text-align: center !important;
    font-family: Mulish !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0.30000001192092896px !important;
    margin-bottom: 6px !important;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .email_congrats{

    display: block !important;

    svg{
      font-size: 20px;
      vertical-align: middle;
    }

    a{
      color: inherit;
      text-decoration: underline;
    }
  }
  .congrats{
    color: #4cde67;



    img{
      height: 31px;
      vertical-align: middle;
    }

  }
  .non-congrats{
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;

    p{
      margin: 2rem 0;
    }
  }
  
  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 25px 0 15px 0;
    padding: 25px 15px;
    font-family: Mulish;

    @media only screen and (min-width: 600px) {
      padding: 25px 75px;
    }

    .social {
      justify-content: center !important;
      align-items: center;
      width: 100% !important;

      @media only screen and (min-width: 600px) {
        width: 80% !important;
      }

      p{
        @media (max-width: 1000px) {
          width: 100% !important;
          display: flex;
          justify-content: space-around;  
        }
      }

      .repContainer {
        flex-basis: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0;
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 0;

        p {
          margin-bottom: 0 !important;
          font-size: 15px;
        }

        @media only screen and (min-width: 600px) {
          margin-left: 20px;
          flex-basis: 30%;
          font-size: 20px;
        }

        @media only screen and (min-width: 750px) {
          margin-top: 0;
        }
      }
    }

    .contact {
      margin-bottom: 30px;
    }

    .contact,
    .social {
      @media only screen and (min-width: 600px) {
        flex-direction: row;
      }
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      img[alt="social-icon-first"] {
        margin-left: 0 !important;
      }

      img[alt="social-icon"],
      img[alt="social-icon-first"] {
        width: 35px;
        height: 35px;
        margin-left: 15px;

        @media only screen and (min-width: 440px) {
          width: 50px;
          height: 50px;
        }
      }

      img[alt="rep-logo"] {
        height: 30px;
        width: 150px;

        @media only screen and (min-width: 440px) {
          width: auto;
          height: 50px;
        }
      }

      p {
        @media only screen and (min-width: 600px) {
          margin-bottom: 0;
        }
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }

  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    // left: 0;
    // top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    // background-color: rgb(0, 0, 0); /* Fallback color */
    background: rgba(60, 100, 177, 0.8);

    // background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    background-color: #222b45;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;

    p {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: white;
    }
  }

  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.socialMediaContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .btn-twitter,
  .btn-linkedIn {
    padding: 15px;
    width: 145px;
    height: 50px;
    background: #0676ed;
    font-family: Mulish;
    font-style: normal;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    border-radius: 20px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
    margin: 0 15px;
    outline: none;
    border: none;
    justify-content: center;
  }

  .btn-twitter {
    background: #FFA500;

    margin-bottom: 10px;
    @media only screen and (min-width: 400px) {
      margin-bottom: 0;
    }
  }

  .btn-linkedIn {
    background: #FFA500 !important;
  }
}

.more_info_container{
  padding: 0 10px 10px;
  text-align: center;
  font-size: 10px;
  font-family: Mulish;
  line-height: 13px;
  color: white;
}

.shareContainer {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  background: #0676ed;
  border-radius: 20px;

  .innerContainer {
    position: relative;
    width: 100%;

    .contentContainer {
      position: relative;
      width: 100%;
      margin-top: 30px;
    }

    .closeContainer {
      padding: 3px 3px 0 0;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;

      button {
        &:focus {
          outline: none;
        }

        outline: none;
        border: none;
        background-color: transparent;
        font-size: 30px;
        color: white;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;

    .titleContainer {
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-size: 15px !important;

      label{
        text-decoration: underline;
        cursor: pointer;
      }
    }

    h2,
    h3 {
      font-family: Mulish;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      color: white;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 10px;
      padding: 0 10px;

      @media only screen and (min-width: 430px) {
        margin-top: 0;
        padding: 0;
      }
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      background-color: white;
      border-radius: 40px;
    }

    .tooltip {
      position: relative;
      z-index: 20;

      .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #2e3a59;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: -5px;
        right: 80%;
        font-size: 12px;
        line-height: 14px;
        font-family: Mulish;

        @media only screen and (min-width: 300px) {
          right: 90%;
        }
      }

      .tooltiptext::after {
        content: "";
        position: absolute;
        top: 15%;
        right: 100%;
        margin-top: -5px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent #2e3a59 transparent transparent;
      }
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  h1 {
    margin-top: 20px;
    color: white;
    font-family: Mulish;
    font-style: normal;
    font-size: 15px;
    text-align: center;
  }

  .inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 10px;
  }

  button {
    z-index: 5;
    display: flex;
    align-items: center;
    border: none;
    background-color: gray;
    border-radius: 20px;
    margin-left: -49px;
    height: 40px;
    padding: 15px;

    &:active {
      background-color: black;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  input {
    background-color: #151a30;
    color: white;
    font-family: Mulish;
    font-style: normal;
    width: 100%;
    height: 40px;

    &:focus {
      background-color: white;
      color: black;
      outline: none;
    }
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
  }

  @media only screen and (min-width: 640px) {
    right: 50px;
    left: auto;
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (min-width: 640px) {
    width: 50% !important;
  }

  @media only screen and (min-width: 1000px) {
    width: 40% !important;
  }
}

.shareButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: none;
  outline: none;
  position: absolute;
  bottom: 10px;
  right: 20px;
  width: 150px;
  height: 150px;
  background: #007ab9;


  p{
    color: white;
    // margin-top: 7px;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
  }

  &:active {
    background-color: black;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  img {
    width: 50%;
    height: 50%;
    margin-bottom: 7px;
  }
}

.showErrorBox,
.showLoadingBox,
.showSuccessBox {
  display: flex !important;
}

.successBox,
.loadingBox {
  padding: 40px;
  background-color: #151a30;
  border-top: 1px solid #212a43;
}

.errorBox {
  background-color: red;
  padding: 20px;
}

.errorBox,
.successBox,
.loadingBox {
  z-index: 5;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
  }

  p {
    color: white;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  button {
    color: black;
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 10px 30px;
    &:hover {
      cursor: pointer;
    }
  }
}
